import axios from 'axios'
import store from "../../store"

var api = axios.create({
    baseURL: baseUrl(),
    headers: {
        'Content-Type': 'application/json',
    },
});

function baseUrl() {
  let hostname = window.location.hostname;

  hostname = hostname.replace('.local.sgmcloud.com.br', '');
  hostname = hostname.replace('.dev.sgmcloud.com.br', '');
  hostname = hostname.replace('.sgmcloud.com.br', '');
  hostname = hostname.replace('.sgmcloud.local', '');

  return `${process.env.VUE_APP_MAIN_API_PREFIX}${hostname}.${process.env.VUE_APP_MAIN_API}/v2`;
}


api.interceptors.request.use((config) => {
      const token = localStorage.getItem('token');

      if (token) {
          config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    }, (err) => {
      return Promise.reject(err);
    });

api.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401 && error.response.data.codigo && error.response.data.codigo === 1) {
        store.dispatch('auth/ActionSignOut');
        window._Vue.$router.push({ path: `/login`, query: {previous: window._Vue.$router.currentRoute.path} });
      }

      return Promise.reject(error);
    });

export default api
