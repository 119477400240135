import services from '@/http'
import api from '@/modules/api'
import * as storage from '../storage'
import * as types from './mutation-types'

function baseUrl() {
    let hostname = window.location.hostname;
  
    hostname = hostname.replace('.local.sgmcloud.com.br', '');
    hostname = hostname.replace('.dev.sgmcloud.com.br', '');
    hostname = hostname.replace('.sgmcloud.com.br', '');
    hostname = hostname.replace('.sgmcloud.local', '');
  
    return `${process.env.VUE_APP_MAIN_API_PREFIX}${hostname}.${process.env.VUE_APP_MAIN_API}/v2`;
}

export const ActionGetUserData = ({ dispatch }, payload) => {
    dispatch('ActionSetToken', payload);

    return api.get(`${baseUrl()}/session`).then(async res => {
        dispatch('ActionSetUser', res.data.login);
        dispatch('ActionSetUserData', res.data);
    }).catch(() => {
        console.log('erro ao obter dados da sessão');
        // dispatch('ActionSetToken', '');
    });
}

export const ActionCheckCompanyData = async ({ dispatch }) => {
    // const localCompanyData = storage.getLocalCompanyData();
    // if (localCompanyData) {
    //     return Promise.resolve(localCompanyData)
    // }

    return api.get('/session/sgm-account/stats').then(res => {
        res.data._status = 200;
        dispatch('ActionSetCompanyData', res.data);
    }).catch(err => {
        const data = {
            _status: 500,
        };
        if (err.response && err.response.status) {
            data._status = err.response.status;
        }
        dispatch('ActionSetCompanyData', data);
        // console.error('erro ao obter dados da empresa', data);
    });
}

export const ActionCheckToken = ({ dispatch, state }) => {
    if (state.token) {
        return Promise.resolve(state.token)
    }

    const token = storage.getLocalToken();
    const userData = JSON.parse(storage.getLocalUserData());

    if (!token || !userData || !userData.userName) {
        return Promise.reject(new Error('Token inválido'))
    }

    dispatch('ActionSetToken', token)
    dispatch('ActionSetUserData', userData);
    return dispatch('ActionLoadSession');
}

export const ActionLoadSession = ({ dispatch }) => {
    return (async (resolve, reject) => {
      try {
        const { data: { user } } = await services.auth.loadSession()

        dispatch('ActionSetUser', user)
        resolve()
      } catch (err) {
        dispatch('ActionSignOut')
        reject(err)
      }
    })
  }

export const ActionSetUser = ({ commit }, payload) => {
    commit(types.SET_USER, payload);
}

export const ActionSetUserData = ({ commit }, payload) => {
    storage.setLocalUserData(JSON.stringify(payload))
    commit(types.SET_USER_DATA, payload);
}

export const ActionSetCompanyData = ({ commit }, payload) => {
    storage.setLocalCompanyData(JSON.stringify(payload))
    commit(types.SET_COMPANY_DATA, payload);

    storage.setCompanyDataLoaded(true);
    commit(types.SET_COMPANY_DATA_LOADED, true);
}

export const ActionSetToken = ({ commit }, payload) => {
    storage.setLocalToken(payload)
    storage.setHeaderToken(payload)
    commit(types.SET_TOKEN, payload);
}

export const ActionSignOut = ({ dispatch }) => {
    storage.setHeaderToken('')
    storage.deleteLocalToken()
    dispatch('ActionSetUser', {})
    dispatch('ActionSetUserData', {})
    dispatch('ActionSetToken', '')
}