import Vue from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import router from './router'
import store from './store'
import "./styles/main.scss";
import { Datetime } from 'vue-datetime'
import VueTheMask from 'vue-the-mask'
import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'

library.add(faUserSecret, faSpinner)
library.add(faFontAwesome)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

import VuejsDialog from 'vuejs-dialog';
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import 'vue-datetime/dist/vue-datetime.css';

// Tell Vue to install the plugin.
Vue.use(VuejsDialog);
Vue.use(Datetime);
Vue.use(VueTheMask);
Vue.use(VueGoogleMap, {
  load: {
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: [/* rest of libraries */]
  }
});

import directives from "./directives/";

Vue.directive(directives);

window._Vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
