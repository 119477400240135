import * as types from './mutation-types'

export default {
    [types.SET_COMPANY_DATA](state, payload) {
        state.companyData = payload;
    },
    [types.SET_COMPANY_DATA_LOADED](state, payload) {
        state.companyDataLoaded = payload;
    },
    [types.SET_USER](state, payload) {
        state.user = payload;
    },
    [types.SET_USER_DATA](state, payload) {
        state.userData = payload;
    },
    [types.SET_TOKEN](state, payload) {
        state.token = payload;
    }
}