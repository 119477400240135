import confirmacao from './confirmacao'
import alerta from './alerta'
import info from './info'
import erroHttp from './errohttp'

/**
 * referência: https://www.npmjs.com/package/vuejs-dialog
 */

export default {
    confirmacao,
    alerta,
    info,
    erroHttp,
}
