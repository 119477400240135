import alerta from './alerta'

function erroHttp(error) {
    let mensagem = '';

    switch (true) {
        case (error.response && error.response.status === 401 && error.response.data.codigo === 1):
            return;

        case (error.response && error.response.status === 400 && (!!error.response.data.message)):
            mensagem = error.response.data.message;
            break;

        case (error.response && error.response.status):
            mensagem = `Erro ${error.response.status}: ${error.response.statusText}`;
            break;

        case (!!error.message):
            mensagem = `${error.message}`;
            break;

        default:
            mensagem = 'Erro (0) desconhecido';
            console.log({error});
    }

    return alerta(mensagem);
}

export default erroHttp