<template>
  <div id="dominio-invalido" class="row m-0 justify-content-center">
    <div class="col-auto p-4">
      <div v-if="carregandoDadosEmpresa" class="d-grid gap-2 text-center">
        <h1>SGM Cloud</h1>
        <h3 class="mt-3">Carregando dados da empresa</h3>
        <p class="small mt-3">
          aguarde...
          <font-awesome-icon :icon="['fas', 'spinner']" spin fixed-width class="ms-1"/>
        </p>
      </div>
      <div v-if="!carregandoDadosEmpresa" class="d-grid gap-2 text-center">
        <h1>SGM Cloud</h1>
        <h3 class="mt-3">Domínio inválido</h3>
        <p class="small mt-3">
          O endereço que você digitou é inválido, verifique se você digitou o endereço corretamente. Em caso de dúvidas entre em contato com o suporte
          <a href="#" target="_blank" rel="noopener">+55 (31) 98695-0236</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//import storage from '@/storage'
// import store from '@/store'

export default {
  name: 'DominioInvalido',
  data() {
    return {
      carregandoDadosEmpresa: true,
      intervaloAtualizaCarregando: undefined,
    }
  },
  methods: {
    atualizaCarregando() {
      this.carregandoDadosEmpresa = (localStorage.getItem('companyDataLoaded') !== 'true');
    },
  },
  created() {
    this.intervaloAtualizaCarregando = setInterval(this.atualizaCarregando, 250);
  },
  destroyed() {
    clearInterval(this.intervaloAtualizaCarregando);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#dominio-invalido {
  height: 100vh;
  background-image: url('/image/bg/login-form-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #012c6e;
}

a {
  color: #a2c7ff;
}
</style>
