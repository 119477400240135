<template>
  <div class="main-sidebar">
    <a v-on:click.prevent="toggleMenu('/', true)" id="hidden-main-sidebar-home" class="d-none"></a>
    <ul class="list-group list-group-flush">

      <a v-on:click.prevent="toggleMenu('/')"
          v-bind:class="menuClass('/')"
          href="/"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <font-awesome-icon :icon="['fas', 'tachometer-alt']" fixed-width class="me-2" />Dashboard
        </span>
      </a>

      <a v-on:click.prevent="toggleMenu('/clientes')"
          v-bind:class="menuClass('/clientes')"
          href="/clientes"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <font-awesome-icon :icon="['fas', 'user']" fixed-width class="me-2" />Clientes
        </span>
      </a>

      <a v-on:click.prevent="toggleMenu('/assinaturas-isp')"
          v-bind:class="menuClass('/assinaturas-isp')"
          href="/assinaturas-isp"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <font-awesome-icon :icon="['fas', 'retweet']" fixed-width class="me-2" />Assinaturas
        </span>
      </a>

      <a v-on:click.prevent="toggleMenu('/isp/connections')"
          v-bind:class="menuClass('/isp/connections')"
          href="/isp/connections"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <font-awesome-icon :icon="['fas', 'network-wired']" fixed-width class="me-2" />Conexões
        </span>
      </a>

      <a v-on:click.prevent="toggleMenu('/helpdesk')"
          v-bind:class="menuClass('/helpdesk')"
          href="/helpdesk"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <font-awesome-icon :icon="['fas', 'hands-helping']" fixed-width class="me-2" />Helpdesk
        </span>
        <div>
          <span class="badge rounded-pill bg-primary">{{ ticketCounters.mine }}</span>
          <span v-if="(ticketCounters.myLate)" class="badge rounded-pill bg-warning ms-1">{{ ticketCounters.myLate }}</span>
        </div>
      </a>

      <a v-on:click.prevent="toggleMenu('/provisionamento-onus')"
          v-bind:class="menuClass('/provisionamento-onus')"
          href="/provisionamento-onus"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <img src="/image/icon/fiber-icon-22.png" width="17" class="me-2">Provisionamento
        </span>
      </a>

      <!-- <a v-on:click.prevent="toggleMenu('/controle-patrimonial')"
          v-bind:class="menuClass('/controle-patrimonial')"
          href="/controle-patrimonial"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <font-awesome-icon :icon="['fas', 'window-restore']" fixed-width class="me-2" />Controle patrimonial
        </span>
      </a> -->

      <a v-on:click.prevent="toggleMenu('/isp/mapa-clientes')"
          v-bind:class="menuClass('/isp/mapa-clientes')"
          href="/isp/mapa-clientes"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <font-awesome-icon :icon="['far', 'map']" fixed-width class="me-2" />Mapa de clientes
        </span>
      </a>

      <a v-on:click.prevent="toggleMenu('/route-monitor')"
          v-bind:class="menuClass('/route-monitor')"
          href="/route-monitor"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span>
          <font-awesome-icon :icon="['fas', 'route']" fixed-width class="me-2" />Monitor de rotas
        </span>
      </a>



      <a v-on:click.prevent="toggleSubmenu('reports')"
          href="#"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'chart-pie']" fixed-width class="me-2" />Relatórios
        </span>
        <font-awesome-icon :icon="['fas', 'angle-right']" fixed-width class="me-2" />
        <font-awesome-icon v-if="false" :icon="['fas', 'angle-down']" fixed-width class="me-2" />
      </a>

      <!-- <a v-if="submenuAberto('reports')"
          v-on:click.prevent="toggleMenu('/reports/customers-without-boleto')"
          v-bind:class="menuClass('/reports/customers-without-boleto')"
          href="/reports/customers-without-boleto"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'file-alt']" fixed-width class="me-2" />Clientes sem boleto
        </span>
      </a> -->

      <a v-if="submenuAberto('reports')"
          v-on:click.prevent="toggleMenu('/reports/overdue-boletos')"
          v-bind:class="menuClass('/reports/overdue-boletos')"
          href="/reports/overdue-boletos"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'file-alt']" fixed-width class="me-2" />Boletos vencidos
        </span>
      </a>

      <a v-on:click.prevent="toggleSubmenu('configuracoes')"
          href="#"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'wrench']" fixed-width class="me-2" />Configurações
        </span>
        <font-awesome-icon :icon="['fas', 'angle-right']" fixed-width class="me-2" />
        <font-awesome-icon v-if="false" :icon="['fas', 'angle-down']" fixed-width class="me-2" />
      </a>

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/servidores')"
          v-bind:class="menuClass('/configuracoes/servidores')"
          href="/configuracoes/servidores"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'server']" fixed-width class="me-2" />Servidores
        </span>
      </a>

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/ip-pools')"
          v-bind:class="menuClass('/configuracoes/ip-pools')"
          href="/configuracoes/ip-pools"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'poll']" fixed-width class="me-2" />IP Pool
        </span>
      </a>

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/catalogo')"
          v-bind:class="menuClass('/configuracoes/catalogo')"
          href="/configuracoes/catalogo"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'briefcase']" fixed-width class="me-2" />Catálogo (produtos/planos)
        </span>
      </a>

      <!--
      <a v-on:click.prevent="toggleMenu('configuracoes')"
          v-if="submenuAberto('configuracoes')"
          href="#"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'users']" fixed-width class="me-2" />Funcionários
        </span>
      </a>
      -->

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/helpdesk')"
          v-bind:class="menuClass('/configuracoes/helpdesk')"
          href="/configuracoes/helpdesk"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'hands-helping']" fixed-width class="me-2" />Helpdesk
        </span>
      </a>

      <!-- <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/colaboradores')"
          v-bind:class="menuClass('/configuracoes/colaboradores')"
          href="/configuracoes/colaboradores"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'users']" fixed-width class="me-2" />Colaboradores
        </span>
      </a> -->

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/contas-financeiras')"
          v-bind:class="menuClass('/configuracoes/contas-financeiras')"
          href="/configuracoes/contas-financeiras"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'cash-register']" fixed-width class="me-2" />Contas financeiras (caixas)
        </span>
      </a>

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/contas-bancarias')"
          v-bind:class="menuClass('/configuracoes/contas-bancarias')"
          href="/configuracoes/contas-bancarias"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'piggy-bank']" fixed-width class="me-2" />Integrações bancárias
        </span>
      </a>

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/integracoes')"
          v-bind:class="menuClass('/configuracoes/integracoes')"
          href="/configuracoes/integracoes"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'plug']" fixed-width class="me-2" />Integrações
        </span>
      </a>

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/parametros')"
          v-bind:class="menuClass('/configuracoes/parametros')"
          href="/configuracoes/parametros"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <!-- <font-awesome-icon :icon="['far', 'keyboard']" fixed-width class="me-2" />Parâmetros -->
          <font-awesome-icon :icon="['fas', 'check-double']" fixed-width class="me-2" />Parâmetros
        </span>
      </a>


      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/contas-de-usuario')"
          v-bind:class="menuClass('/configuracoes/contas-de-usuario')"
          href="/configuracoes/contas-de-usuario"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['fas', 'key']" fixed-width class="me-2" />Contas de usuário
        </span>
      </a>

      <a v-if="submenuAberto('configuracoes')"
          v-on:click.prevent="toggleMenu('/configuracoes/meu-negocio')"
          v-bind:class="menuClass('/configuracoes/meu-negocio')"
          href="/configuracoes/meu-negocio"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center is-sub">
        <span>
          <font-awesome-icon :icon="['far', 'building']" fixed-width class="me-2" />Meu negócio
        </span>
      </a>
    </ul>
  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'MainSidebar',
  data() {
    return {
      lastClicked: '',
      submenu: '',
      ticketCounters: '0',
    }
  },
  methods: {
    toggleMenu(menu, veioDoHidden) {
      this.lastClicked = menu;

      if (this.$route.path === menu) {
        return;
      }

      this.$router.push({ path: menu });
      //event.currentTarget.classList.toggle('active');

      if (!veioDoHidden) {
        document.getElementById('sidebar-toggle').click();
      }
    },
    toggleSubmenu(submenu) {
      this.lastClicked = submenu;
      this.submenu = submenu;
      //event.currentTarget.classList.toggle('active');
    },
    menuClass(menu) {
      const classes = [];
      let menuMatch = false;

      /*if (menu === '/') {
        menuMatch = (this.$route.path === menu);
      } else {
        menuMatch = (this.$route.path.includes(menu));
      }*/
      menuMatch = (this.$route.path === menu);

      if (menuMatch) {
        classes.push('active');
      }

      return classes;
    },
    submenuClass(submenu) {
      return [{submenu}];
    },
    submenuAberto(submenu) {
      return (this.submenu === submenu);
    },

    updateTicketCounter() {
      api.get(`/tickets/counters`).then(res => {
        this.ticketCounters = res.data;
      });
    },
  },
  created() {
    this.updateTicketCounter();
    this.intervaloContadores = setInterval(this.updateTicketCounter, 30000);
  },
}
</script>

<style>
.main-sidebar {
  display: none;
  z-index: 1002;
  width: 270px;
  height: calc(100vh - 100px);
  color: #ddd;

  position:absolute;
  top:100px;
  left: 0px;
  overflow-y: auto;
}

.main-sidebar .list-group-item {
  height: 44px;
  background-color: transparent !important;
  color: #aaa;
  line-height: 1.42857143;
  border-left: 4px solid transparent;
}

.main-sidebar .list-group-item.is-sub {
  height: 34px;
  padding-left: 40px;
  background-color: rgba(150, 150, 150, 0.05) !important;
}

.main-sidebar .list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: #fff;
}

/* STYLING SCROLLBAR */

::-webkit-scrollbar {
  width: 1vw; /* THIS WILL RESET THE DEFAULT SCORLLBAR STYLING */
}

/* TO STYLE THE SCROLLBAR TRACK */
::-webkit-scrollbar-track {
  background-color: rgba(150, 150, 150, 0.05); /* THIS WILL SET THE COLOR OF THE SCROLLBAR TRACK */
}

/* TO STYLE THE SCROLLBAR THUMB */
::-webkit-scrollbar-thumb {
  background-color: #71717152;
  border: 0.3vw solid #29d8ff32;
  border-radius: 5vw;
}
</style>
