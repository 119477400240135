<template>
  <div class="home">
    <div class="row w-100 m-0">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
        <div class="card text-white bg-secondary h-100">
          <div class="card-header">Assinaturas ativas</div>
          <div class="card-body text-end">
            <h5 class="card-title">
              <span class="icone float-start"><font-awesome-icon :icon="['fas', 'users']" size="2x" fixed-width class="me-1"/></span>
              0
            </h5>
            <p class="card-text"></p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
        <div class="card text-white bg-secondary h-100">
          <div class="card-header">Clientes conectados</div>
          <div class="card-body text-end">
            <h5 class="card-title">
              <span class="icone float-start"><font-awesome-icon :icon="['fas', 'network-wired']" size="2x" fixed-width class="me-1"/></span>
              0
            </h5>
            <p class="card-text">0%</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
        <div class="card bg-warning h-100">
          <div v-on:click="navegaPara('/helpdesk')" class="card-header cursor-pointer">
            <span>
              <font-awesome-icon :icon="['fas', 'hands-helping']" fixed-width class="me-2" />Chamados
            </span>
          </div>
          <div class="card-body text-end">
            <h5 class="card-title">
              <span class="icone float-start "><font-awesome-icon :icon="['fas', 'wrench']" size="2x" fixed-width class="me-1"/></span>
              <span v-on:click="navegaPara('/helpdesk')" class="cursor-pointer ps-2">{{contadoresHelpDesk.mine}}</span>
            </h5>
            <p class="card-text">em atraso - {{contadoresHelpDesk.myLate}}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '@/modules/api'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      contadoresHelpDesk: {
        mine: 0,
        myLate: 0,
      },
      intervaloAtualizaContadoresHelpdesk: undefined,
    }
  },
  methods: {
    navegaPara(url) {
      this.$router.push({ path: url });
    },

    atualizaContadoresHelpdesk() {
      api.get(`/tickets/counters`).then(res => {
        this.contadoresHelpDesk = res.data;
      });
    },
  },
  created() {
    this.atualizaContadoresHelpdesk();
    this.intervaloAtualizaContadoresHelpdesk = setInterval(this.atualizaContadoresHelpdesk, 5000);
  },
  destroyed() {
    clearInterval(this.intervaloAtualizaContadoresHelpdesk);
  },
}
</script>

<style scoped>
.card-title > .icone {
    opacity: 0.25;
}
</style>
