<template>
  <div class="main-pages">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'MainPages',
  computed: {
    /*podeExibirMain() {
      return !!store.getters['auth/hasToken'];
    }*/
  },
  methods: {
    mainClass() {
      return [
          //'sidebar-open',
          'skin-blue'
        ]
    }
  }
}
</script>

<style>
.main-pages {
  min-height: calc(100vh - 100px);
  background-color: #ecf0f5;
}

.main-pages > .main-pages {
  padding: 8px;
}
</style>
