import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import beforeEach from './beforeEach'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../components/LoginPage.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/clientes',
    name: 'Customers',
    component: () => import(/* webpackChunkName: "Customers" */ '../views/Customers.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/clientes/novo',
    name: 'CustomersNew',
    component: () => import(/* webpackChunkName: "CustomersEdit" */ '../views/CustomersEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/clientes/:customerID',
    name: 'CustomerView',
    component: () => import(/* webpackChunkName: "CustomerView" */ '../views/CustomerView.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/clientes/:customerID/editar',
    name: 'CustomersEdit',
    component: () => import(/* webpackChunkName: "CustomersEdit" */ '../views/CustomersEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/customers/:customerID/new-subscription',
    name: 'NewSubscription',
    component: () => import(/* webpackChunkName: "EditSubscription" */ '../views/customer/EditSubscription.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/customers/:customerID/subscriptions/:subscriptionID/edit',
    name: 'EditSubscription',
    component: () => import(/* webpackChunkName: "EditSubscription" */ '../views/customer/EditSubscription.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/helpdesk',
    name: 'Helpdesk',
    component: () => import(/* webpackChunkName: "Helpdesk" */ '../views/Helpdesk.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/isp/connections',
    name: 'IspConnections',
    component: () => import(/* webpackChunkName: "IspConnections" */ '../views/IspConnections.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/isp/connections/online',
    name: 'IspConnectionsOnline',
    component: () => import(/* webpackChunkName: "IspConnectionsOnline" */ '../views/IspConnectionsOnline.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/isp/connections/offline',
    name: 'IspConnectionOffline',
    component: () => import(/* webpackChunkName: "IspConnectionOffline" */ '../views/IspConnectionOffline.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/isp/connections/errors',
    name: 'IspConnectionsErrors',
    component: () => import(/* webpackChunkName: "IspConnectionsErrors" */ '../views/IspConnectionsErrors.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/isp/connections/history',
    name: 'IspConnectionsHistory',
    component: () => import(/* webpackChunkName: "IspConnectionsHistory" */ '../views/IspConnectionsHistory.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/isp/mapa-clientes',
    name: 'IspMapaClientes',
    component: () => import(/* webpackChunkName: "IspMapaClientes" */ '../views/IspMapaClientes.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/route-monitor',
    name: 'RouteMonitor',
    component: () => import(/* webpackChunkName: "RouteMonitor" */ '../views/RouteMonitor.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/helpdesk/tickets/:ticketID/ver',
    name: 'Ticket',
    component: () => import(/* webpackChunkName: "Ticket" */ '../views/Ticket.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/helpdesk/tickets/:ticketID/editar',
    name: 'TicketEdit',
    component: () => import(/* webpackChunkName: "Ticket" */ '../views/TicketEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/assinaturas-isp',
    name: 'Assinaturas',
    component: () => import(/* webpackChunkName: "Assinaturas" */ '../views/AssinaturasISP.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/assinaturas-isp/:assinaturaID/mensalidades',
    name: 'MensalidadesISP',
    component: () => import(/* webpackChunkName: "MensalidadesISP" */ '../views/MensalidadesISP.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/assinaturas-isp/:assinaturaID/mensalidades/:mensalidadeID',
    name: 'MensalidadesISPView',
    component: () => import(/* webpackChunkName: "MensalidadesISPView" */ '../views/MensalidadesISPView.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/provisionamento-onus',
    name: 'ProvisionamentoOnus',
    component: () => import(/* webpackChunkName: "ProvisionamentoOnus" */ '../views/ProvisionamentoOnus.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/provisionamento-onus/aguardando',
    name: 'OnuAguardando',
    component: () => import(/* webpackChunkName: "OnuAguardando" */ '../views/OnuAguardando.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/provisionamento-onus/onus',
    name: 'Onus',
    component: () => import(/* webpackChunkName: "Onus" */ '../views/Onus.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/controle-patrimonial',
    name: 'ControlePatrimonial',
    component: () => import(/* webpackChunkName: "ControlePatrimonial" */ '../views/ControlePatrimonial.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/reports/overdue-boletos',
    name: 'ReportOverdueBoletos',
    component: () => import(/* webpackChunkName: "ReportOverdueBoletos" */ '../views/ReportOverdueBoletos.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores',
    name: 'Servidores',
    component: () => import(/* webpackChunkName: "Servidores" */ '../views/Servidores.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores/bngs/novo',
    name: 'ServidoresBNGNew',
    component: () => import(/* webpackChunkName: "ServidoresBNGNew" */ '../views/ServidoresBNGEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores/bngs/:id',
    name: 'ServidoresBNGEdit',
    component: () => import(/* webpackChunkName: "ServidoresBNGEdit" */ '../views/ServidoresBNGEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores/radius-ips',
    name: 'IspRadiusIP',
    component: () => import(/* webpackChunkName: "IspRadiusIP" */ '../views/IspRadiusIP.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores/radius-ips/novo',
    name: 'IspRadiusIPNew',
    component: () => import(/* webpackChunkName: "IspRadiusIPEdit" */ '../views/IspRadiusIPEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores/radius-ips/:ipID/edit',
    name: 'IspRadiusIPEdit',
    component: () => import(/* webpackChunkName: "IspRadiusIPEdit" */ '../views/IspRadiusIPEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores/olts/:oltID',
    name: 'ServidoresOLTManage',
    component: () => import(/* webpackChunkName: "ServidoresOLTManage" */ '../views/ServidoresOLTManage.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores/olts/:oltID/placa-uplink/:placaID',
    name: 'ServidoresOLTPlacaUplink',
    component: () => import(/* webpackChunkName: "ServidoresOLTPlacaUplink" */ '../views/ServidoresOLTPlacaUplink.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/servidores/olts/:oltID/placa-uplink/:placaID/porta/:porta',
    name: 'ServidoresOLTPlacaUplinkPorta',
    component: () => import(/* webpackChunkName: "ServidoresOLTPlacaUplinkPorta" */ '../views/ServidoresOLTPlacaUplinkPorta.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/ip-pools',
    name: 'IPPools',
    component: () => import(/* webpackChunkName: "IPPools" */ '../views/IPPools.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/catalogo',
    name: 'Catalogo',
    component: () => import(/* webpackChunkName: "Catalogo" */ '../views/Catalogo.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/colaboradores',
    name: 'Colaboradores',
    component: () => import(/* webpackChunkName: "Colaboradores" */ '../views/Colaboradores.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/contas-bancarias',
    name: 'Banks',
    component: () => import(/* webpackChunkName: "Banks" */ '../views/Banks.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/contas-financeiras',
    name: 'Wallets',
    component: () => import(/* webpackChunkName: "Wallets" */ '../views/Wallets.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/contas-financeiras/novo',
    name: 'WalletNew',
    component: () => import(/* webpackChunkName: "WalletNew" */ '../views/WalletEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/contas-financeiras/:id/edit',
    name: 'WalletEdit',
    component: () => import(/* webpackChunkName: "WalletEdit" */ '../views/WalletEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/contas-bancarias/novo',
    name: 'BanksNew',
    component: () => import(/* webpackChunkName: "BanksEdit" */ '../views/BanksEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/contas-bancarias/:contaID/editar',
    name: 'BanksEdit',
    component: () => import(/* webpackChunkName: "BanksEdit" */ '../views/BanksEdit.vue') // lazy-loaded when the route is visited.
  },

  {
    path: '/configuracoes/contas-de-usuario',
    name: 'ContasDeUsuario',
    component: () => import(/* webpackChunkName: "ContasDeUsuario" */ '../views/ContasDeUsuario.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/helpdesk',
    name: 'ConfigHelpdesk',
    component: () => import(/* webpackChunkName: "ConfigHelpdesk" */ '../views/ConfigHelpdesk.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/integracoes',
    name: 'ConfigIntegrations',
    component: () => import(/* webpackChunkName: "ConfigIntegrations" */ '../views/ConfigIntegrations.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/integracoes/zabbix/adicionar',
    name: 'ConfigIntegrationsZabbixCreate',
    component: () => import(/* webpackChunkName: "ConfigIntegrationsZabbixCreate" */ '../views/ConfigIntegrationsZabbixCreate.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/integracoes/zabbix/:integrationId',
    name: 'ConfigIntegrationsZabbixView',
    component: () => import(/* webpackChunkName: "ConfigIntegrationsZabbixView" */ '../views/ConfigIntegrationsZabbixView.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/integracoes/zabbix/:integrationId/editar',
    name: 'ConfigIntegrationsZabbixEdit',
    component: () => import(/* webpackChunkName: "ConfigIntegrationsZabbixEdit" */ '../views/ConfigIntegrationsZabbixEdit.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/parametros',
    name: 'ConfigParametros',
    component: () => import(/* webpackChunkName: "ConfigParametros" */ '../views/ConfigParametros.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/meu-negocio',
    name: 'ConfigBusiness',
    component: () => import(/* webpackChunkName: "ConfigBusiness" */ '../views/ConfigBusiness.vue') // lazy-loaded when the route is visited.
  },
  {
    path: '/configuracoes/meu-negocio/company/edit',
    name: 'ConfigCompany',
    component: () => import(/* webpackChunkName: "ConfigCompany" */ '../views/ConfigCompanyEdit.vue') // lazy-loaded when the route is visited.
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(beforeEach)

export default router
