<template>
    <div class="login-form p-4 border">
      <!-- página de login -->
      <div v-if="podeExibirLogin" class="form-group">
        <div class="d-grid gap-2">
          <h1 class="text-center">SGM Cloud</h1>
          <h3>Entrar</h3>
          <div class="form-group">
            <label for="login-login">Usuario</label>
            <input v-model="loginForm.login"
                v-on:keyup.enter="$refs.senha.focus()"
                v-bind:disabled="efetuandoLogin"
                type="text"
                ref="usuario"
                class="form-control"
                placeholder="Seu nome de usuário">
          </div>
          <div class="form-group">
            <label for="login-senha">Senha</label>
            <input v-model="loginForm.senha"
                v-on:keyup.enter="efetuaLogin()"
                v-bind:disabled="efetuandoLogin"
                type="password"
                ref="senha"
                class="form-control"
                placeholder="Insira sua senha">
          </div>
            <button v-on:click="efetuaLogin"
                v-bind:disabled="!podeEfetuarLogin() || efetuandoLogin"
                type="button"
                class="btn btn-success">
              <font-awesome-icon v-if="!efetuandoLogin" :icon="['fas', 'user-secret']" fixed-width />
              <font-awesome-icon v-if="efetuandoLogin" :icon="['fas', 'spinner']" fixed-width spin />
              Acessar sistema
            </button>
          <div class="row justify-content-between small">
            <div class="col-auto small">
              <div class="form-check">
                <input class="form-check-input" v-model="loginForm.lembrar" type="checkbox" id="login-lembrar">
                <label class="form-check-label" for="flexCheckChecked">
                  Lembrar-me
                </label>
              </div>
            </div>
            <div class="col-auto small">
              <p class="text-end">
                <a v-on:click.prevent="exibeRecuperacaoSenha" href="#">Esqueci a senha</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- página de login -->

      <!-- esqueceu a senha -->
      <div v-if="podeExibirRecuperacaoSenha" class="form-group">
        <div class="d-grid gap-2">
          <h1 class="text-center">SGM Cloud</h1>
          <h6 class="small">
            <a v-on:click.prevent="voltaParaLogin" href="#" class="small">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />Voltar
            </a>
          </h6>
          <h3>Recuperação de senha</h3>
          <div class="form-group">
            <label for="recuperacao-senha-email">E-mail</label>
            <input type="text" class="form-control" placeholder="Digite seu e-mail">
          </div>
          <button v-on:click="efetuaLogin" class="btn btn-success" type="button" v-bind:disabled="true">
            <font-awesome-icon v-if="!efetuandoLogin" :icon="['fas', 'user-lock']" fixed-width />
            <font-awesome-icon v-if="efetuandoLogin" :icon="['fas', 'spinner']" fixed-width spin />
            Recuperar
          </button>
        </div>
      </div>
      <!-- esqueceu a senha -->
    </div>
</template>


<script>
import { mapActions } from 'vuex'
import dialogo from '@/modules/dialogo'
import api from '@/modules/api'

export default {
  name: 'LoginPage',
  data() {
    return {
      loginForm: {
        login: '',
        senha: '',
        lembrar: true,
      },
      paginaAtiva: 'login',
      efetuandoLogin: false,
    }
  },
  computed: {
    podeExibirLogin() {
      return (this.paginaAtiva === 'login');
    },
    podeExibirRecuperacaoSenha() {
      return (this.paginaAtiva === 'recuperacao-senha');
    },
  },
  methods: {
    ...mapActions('auth', ['ActionGetUserData', 'ActionSetToken']),

    podeEfetuarLogin() {
      return (this.loginForm.login && this.loginForm.senha);
    },

    exibeRecuperacaoSenha() {
      this.paginaAtiva = 'recuperacao-senha';
    },

    voltaParaLogin() {
      this.paginaAtiva = 'login';
    },

    async efetuaLogin() {
      this.efetuandoLogin = true;
      this.ActionSetToken('');

      try {
        const sendData = {
          login: this.loginForm.login,
          password: this.loginForm.senha,
        };

        await api.post('/session', sendData).then(async (res) => {
          await this.ActionGetUserData(res.data.token).then(() => {
            if (this.$route.query.previous) {
              this.$router.push({ path: this.$route.query.previous });
            } else {
              this.$router.push({ name: 'Home' });
            }
            this.efetuandoLogin = false;
          }).
          catch(() => {
            dialogo.alerta('Erro ao efetuar o login. Verifique o usuário e senha e tente novamente.');
            this.efetuandoLogin = false;
          });
        }).catch(err => {
          this.efetuandoLogin = false;
          if (err.response && err.response.status == 401) {
            dialogo.alerta('Erro ao efetuar o login. Verifique o usuário e senha e tente novamente.');
          } else {
            console.log(err);
            dialogo.alerta('Houve um erro desconhecido ao tentar fazer login, tente novamente.');
          }
        });

        // await this.ActionGetUserData(sendData).then(() => {
        //   if (this.$route.query.previous) {
        //     this.$router.push({ path: this.$route.query.previous });
        //   } else {
        //     this.$router.push({ name: 'Home' });
        //   }
        //   this.efetuandoLogin = false;
        // }).
        // catch(() => {
        //   dialogo.alerta('Erro ao efetuar o login. Verifique o usuário e senha e tente novamente.');
        //   this.efetuandoLogin = false;
        // });
      } catch (error) {
        alert(error.data ? error.data.message : 'Não foi possível fazer o login' )
        this.efetuandoLogin = false;
      }
    }
  },
  mounted() {
    this.$refs.usuario.focus();
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-form {
  height: 100vh;
  /*width: 350px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/image/bg/login-form-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-form > div {
  width: 340px;
  align-items: left;
  justify-content: left;
  background-color: rgba(255, 255, 255, 1);
  /* border: 1px solid rgba(14, 115, 230, 0.75); */
  padding: 20px;
  border-radius: 10px;
  /*
  backdrop-filter: blur(5px);
  */
}

label {
  color: #949494;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
