<template>
  <div class="main" v-bind:class="mainClass()">
    <header class="main-header">
      <p class="logo-grande">
        <span v-on:click="goToMain()" class="cursor-pointer">
          <img src="/image/icon/sgmcloud-logo-main.png" class="logo-titulo">
          <strong>SGM</strong>Cloud
        </span>
      </p>
      <div class="row g-0 align-items-center justify-content-between bars">
        <div v-on:click="sidebarToggle()" id="sidebar-toggle" class="col-auto sidebar-toggle">
          <font-awesome-icon :icon="['fas', 'bars']" fixed-width />
        </div>
        <div class="col-auto right-side pe-2">
          <div v-on:click="userMenuToggle()" class="form-group user-menu" v-bind:class="userMenuClass()">
            <img src="/image/icon/avatar.png" class="avatar-icon me-1">
            Olá, {{loggedInUserName()}}
            <font-awesome-icon :icon="['fas', 'angle-down']" fixed-width class="pt-1" />
          </div>
        </div>
      </div>
    </header>

    <div v-on:click="sidebarBgClick()" id="sidebar-open-bg" class="d-none"></div>
    <MainSidebar />

    <div class="row g-0 conteudo">
      <div class="col main-pages">
        <MainPages />
      </div>
    </div>

    <!-- menu do usuário -->
    <div class="form-group user-menu-float" v-bind:class="userMenuClass()">
      <div class="row m-0">
        <div class="col-12 user-menu-float-topo">
          <div class="row">
            <div class="col-auto">
              <img src="/image/icon/avatar.png" class="avatar-icon me-1">
            </div>
            <div class="col dados-usuario">
              <p class="mb-0 d-inline-block text-truncate">Bem vindo,</p>
              <p class="mb-0 d-inline-block text-truncate nome-usuario">{{loggedInUserName()}}</p>
              <p class="mb-0 d-inline-block text-truncate email">{{loggedInUserEmail()}}</p>
            </div>
          </div>
          <div class="row p-0 opcoes-menu">
            <ul class="list-group p-0">
              <li class="list-group-item disabled">
                <p class="opcao">
                  <font-awesome-icon :icon="['fas', 'user-circle']" fixed-width class="pt-1" />
                  Minha conta
                </p>
              </li>
              <li class="list-group-item border-top disabled">
                <p class="opcao">
                  <font-awesome-icon :icon="['fas', 'cogs']" fixed-width class="pt-1" />
                  Configurações
                </p>
              </li>
              <li class="list-group-item border-top">
                <p v-on:click="efetuaLogout()" class="opcao">
                  <font-awesome-icon :icon="['fas', 'sign-out-alt']" fixed-width class="pt-1" />
                  Encerrar sessão
                </p>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <!-- menu do usuário -->
  </div>
</template>

<script>
import MainSidebar from '@/views/MainSidebar.vue'
import MainPages from '@/views/MainPages.vue'
import store from '@/store'
import dialogo from '@/modules/dialogo'
import { mapActions } from 'vuex'

export default {
  name: 'Main',
  components: {
    MainSidebar,
    MainPages,
  },
  data() {
    return {
      sidebarToggleVisible: false,
      menuUsuarioToggleVisible: false,
    };
  },
  computed: {
    podeExibirMain() {
      return !!store.getters['auth/hasToken'];
    }
  },
  methods: {
    ...mapActions('auth', ['ActionSignOut']),

    mainClass() {
      return [
          //'sidebar-open',
          'skin-blue'
        ]
    },

    loggedInUserName() {
      const userData = store.getters['auth/getUserData'];

      return userData.userName;
    },

    loggedInUserEmail() {
      const userData = store.getters['auth/getUserData'];

      return userData.email;
    },

    userMenuClass() {
      if (!this.menuUsuarioToggleVisible) {
        return [];
      }

      return ['aberto'];
    },

    goToMain() {
      document.getElementById('hidden-main-sidebar-home').click();
    },

    sidebarToggle() {
      var elements = document.getElementsByClassName('main-sidebar');
      var elBg = document.getElementById('sidebar-open-bg');
      if (!this.sidebarToggleVisible) {
        elBg.classList.remove("d-none"); // bg
        elements.item(0).classList.add("d-block");   // menu
      } else {
        elBg.classList.add("d-none");     // bg
        elements.item(0).classList.remove("d-block"); // menu
      }

      this.sidebarToggleVisible = !this.sidebarToggleVisible;
    },

    sidebarBgClick() {
      this.sidebarToggle();
    },

    userMenuToggle() {
      this.menuUsuarioToggleVisible = !this.menuUsuarioToggleVisible;
    },

    efetuaLogout() {
      this.ActionSignOut().then(() => {
        this.$router.push({ name: 'Login' });
      }).
      catch(() => {
        dialogo.alerta('Erro desconhecido ao efetuar o logout. Entre em contato com o suporte técnico.');
      });
    },
  }
}
</script>

<style>
strong {
  font-weight: 600;
}

.sidebar-toggle {
    cursor: pointer;
    z-index: 5;
}

.skin-blue .main-header {
    background-color: #007ac0;
    color: #fff;
    border-bottom: 0 solid transparent;
}

.skin-blue .main-header .sidebar-toggle {
    background-color: #007ac0;
    width: 50px;
}

.skin-blue .main-header .bars {
    background-color: #3b91c2;
}

.skin-blue .main-sidebar .list-group-item.active {
  border-top: 2px solid #222d32;
  border-left: 4px solid #007ac0;
  color: #fff;
}

.main-header > .logo-grande {
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  padding: 0 15px;
  font-weight: 300;
  margin: 0;
}

.skin-blue .main-header .row {
    height: 50px;
}

.skin-blue .main-header .sidebar-toggle {
    height: 50px;
    padding: 15px;
}

.skin-blue .wrapper, .skin-blue .main-sidebar,
.skin-blue .left-side {
    background-color: #222d32;
}

.conteudo .main-menu-bg {
  width: 250px;
}

.main-sidebar .list-group-item.active {
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.card-principal {
  border-radius: 3px;
  border-top: solid 3px #888;
  background-color: #fff;
  padding: 4px;
}

@media (max-width: 767px) {
  .sidebar-open .content-wrapper,
  .sidebar-open .main-footer {
      -webkit-transform: translate(230px, 0);
      -ms-transform: translate(230px, 0);
      -o-transform: translate(230px, 0);
      transform: translate(230px, 0);
  }
}

@media (max-width: 767px) {
  .content-wrapper {
      min-height: calc(100vh - 151px);
  }
}

.cursor-pointer{
  cursor: pointer;
}

.avatar-icon {
  height: 42px;
  border-radius: 50%;
  border: 1px solid rgba(151, 203, 252, 0.915);
}

.user-menu {
  height: 50px;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 2px;
  cursor: pointer;
}

.user-menu.aberto,
.user-menu:hover {
  background-color: rgba(255, 255, 255, 0.10);
}

.user-menu-float {
  z-index: 3;
  display: none;
  position: absolute;
  top: 102px;
  width: 300px;
  right: 4px;
  border: 0px solid;
  border-radius: 6px;
  background: linear-gradient(250deg, #3e93d6, #527cc0);
}

.user-menu-float.aberto {
  display: unset;
}

.user-menu-float-topo {
  color: #fff;
  line-height: 17px;
}

.user-menu-float .avatar-icon {
  margin-top: 20px;
}
.user-menu-float .dados-usuario {
  margin-top: 10px;
  padding-bottom: 10px;
}
.user-menu-float .nome-usuario {
  font-weight: 600;
  font-size: 1.1rem;
}
.user-menu-float .email {
  color: rgba(255, 255, 255, 0.50);
  font-size: 0.8rem;
}
.user-menu-float .text-truncate {
  width: 200px;
}

.user-menu-float .opcoes-menu {
  color: rgb(84, 85, 85);
  font-size: 18px;
}

.user-menu-float .list-group {
  background-color: rgb(254, 255, 247);
  border: 1px solid #527cc0;
  border-top-right-radius: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.user-menu-float .list-group-item {
  border: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.user-menu-float .list-group-item:hover {
  background-color: rgba(49, 114, 255, 0.1);
}

.user-menu-float .list-group-item.border-top {
  border-top: 1px solid  rgba(0, 91, 209, 0.2) !important;
}

.user-menu-float .opcao {
  margin-bottom: 0px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}

.list-group-item.disabled {
  color: rgb(206, 206, 206);
}

.logo-titulo {
  max-height: 40px;
  padding-right: 10px;
}

#sidebar-open-bg {
  width: calc(100vw);
  height: calc(100vh);
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1001;
}

.blinking {
  animation: blinking 1s linear infinite;
  -moz-animation: blinking 1s linear infinite;
  -webkit-animation: blinking 1s linear infinite;
  -o-animation: blinking 1s linear infinite;
}
@keyframes blinking {
  50% {
    opacity: 0;
  }
  0%,
  100% {
    opacity: 1;
  }
}

h5 {
  font-size: 1.15rem;
}
</style>
