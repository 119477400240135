import { setBearerToken } from '@/http'

export const setHeaderToken = token => setBearerToken(token)
export const getLocalToken = () => localStorage.getItem('token')
export const deleteLocalToken = () => localStorage.removeItem('token')
export const setLocalToken = token => localStorage.setItem('token', token)
export const getLocalCompanyData = () => localStorage.getItem('companyData')
export const setLocalCompanyData = companyData => localStorage.setItem('companyData', companyData)
export const setCompanyDataLoaded = companyDataLoaded => localStorage.setItem('companyDataLoaded', companyDataLoaded)
export const getCompanyDataLoaded = () => localStorage.getItem('companyDataLoaded')
export const getLocalUserData = () => localStorage.getItem('userData')
export const deleteLocalUserData = () => localStorage.removeItem('userData')
export const setLocalUserData = userData => localStorage.setItem('userData', userData)