<template>
  <div id="app">
    <!-- loading -->
    <div id="all-loading" class="text-center">
      <div class="d-grid">
        <font-awesome-icon :icon="['fas', 'spinner']" spin size="2x"/>
      </div>
    </div>
    <!-- loading -->

    <div v-if="carregando" id="load-company" class="row m-0 justify-content-center">
      <div class="col-auto p-4">
        <div class="d-grid gap-2 text-center">
          <h1>SGM Cloud</h1>
          <h3 class="mt-3">Carregando dados da empresa</h3>
          <p v-if="!tryAgainSecs" class="small mt-3">
            aguarde...
            <font-awesome-icon :icon="['fas', 'spinner']" spin fixed-width class="ms-1"/>
          </p>
            <p v-if="tryAgainSecs" class="small mt-3">
              * Houve um erro. Tentaremos novamente em {{tryAgainSecs}}s...
            </p>
        </div>
      </div>
    </div>
    <DominioInvalido v-if="!carregando && !dominioValido" />
    <!-- <LoginPage/> -->
    <LoginPage v-if="!carregando && deveExibirLogin" />
    <Main v-if="!carregando && podeExibirMain" />
  </div>
</template>

<script>
// @ is an alias to /src
import DominioInvalido from '@/components/DominioInvalido.vue'
import LoginPage from '@/components/LoginPage.vue'
import Main from '@/components/Main.vue'
import store from '@/store'

export default {
  name: 'App',
  components: {
    DominioInvalido,
    LoginPage,
    Main
  },
  data() {
    return {
      dadosEmpresa: true,
      intervaloAtualizaDadosEmpresa: undefined,
      intervaloAtualizaTempoRetry: undefined,
      tryAgainAt: undefined,
      tryAgainSecs: undefined,
    }
  },
  methods: {
    initCompany() {
      this.tryAgainAt = undefined;
      this.tryAgainSecs = undefined;

      store.dispatch('auth/ActionCheckCompanyData').then(() => {
        this.atualizaDadosEmpresa();
        if (!this.dadosEmpresa._status || this.dadosEmpresa._status == 500) {
          setTimeout(() => {
            this.initCompany();
          }, 5000);

          const at = new Date();
          at.setSeconds(at.getSeconds() + 5);
          this.tryAgainAt = at;

          return;
        }
      });
    },

    atualizaDadosEmpresa() {
      this.dadosEmpresa = JSON.parse(localStorage.getItem('companyData'));
    },

    calcTryAgainSecs() {
      if (!this.tryAgainAt) {
        this.tryAgainSecs = undefined;
        return;
      }

      this.tryAgainSecs =  Math.trunc((this.tryAgainAt - new Date().getTime()) / 1000);
      if (!this.tryAgainSecs) {
        this.tryAgainAt = undefined;
      }
    },
  },
  computed: {
    carregando() {
      const company = this.dadosEmpresa;

      return (!company || !company._status || company._status == 500);
    },
    dominioValido() {
      const company = this.dadosEmpresa;

      return (company && company._status == 200 && company.companyName);
    },
    deveExibirLogin() {
      return (this.dominioValido && !store.getters['auth/hasToken']);
    },
    podeExibirMain() {
      return (this.dominioValido && !this.deveExibirLogin);
    },
  },
  created() {
    // this.atualizaDadosEmpresa();
    // this.intervaloAtualizaDadosEmpresa = setInterval(this.atualizaDadosEmpresa, 250);
    this.intervaloAtualizaTempoRetry = setInterval(this.calcTryAgainSecs, 250);
    this.initCompany();
  },
  destroyed() {
    clearInterval(this.intervaloAtualizaDadosEmpresa);
    clearInterval(this.intervaloAtualizaTempoRetry);
  },
}
</script>


<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.modal-dialog.scroll {
  overflow-y: initial !important;
}
.modal-dialog.scroll > .modal-content > .modal-body {
  height: calc(100vh - 190px);
  overflow-y: auto;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#all-loading {
  width: calc(100vw);
  height: calc(100vh);
  background-color: rgba(0, 0, 0, .5);
  color: #fefefe;
  z-index: 5500;
  position: absolute;
  left: 100;
  top: 100;
  display: none;
  align-items: center;
  justify-content: center;
}
#all-loading.show {
  display: flex;
}

#load-company {
  height: calc(100vh);
  background-image: url('/image/bg/login-form-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #012c6e;
}

.tab-pane {
  padding-top: 4px;
}

.modal-fullscreen-margin {
  max-width: calc(100vw - 16px) !important;
}

@media (min-width: 576px) {
  .modal-dialog {
      max-width: calc(100vw - 56px) !important;
  }
}

label.required:after {
  content:"*";
  color:red;
}

/** botões para computador apenas */
@media (min-width: 576px) {
  .table-sm-pc>:not(caption)>*>* {
    padding: .25rem .25rem;
  }
  .table .btn-sm {
    padding: .15rem .35rem !important;
    font-size: .755rem !important;
    border-radius: .2rem !important;
  }
}

/* dialogo de confirmação */
.dg-main-content {
    max-width: 600px !important;
}

.dg-title {
  margin-bottom: 6px !important;
  color: #5f5f5f;
}

.dg-content {
  border-top: 2px solid #E1E6EA;
  padding-top: 6px;
}

.dg-btn.dg-btn--cancel {
  color: #0096D9;
  background-color: #fefefe;
  border-color: #0096D9;
}

.dg-btn.dg-btn--ok {
  color: #fefefe;
  background-color: #0096D9;
}

.dialogo-alerta .dg-title {
  color: #ffb20b;
}

.dialogo-alerta .dg-btn {
  background-color: #ffb20b;
  border-color: #ffb20b;
}
/* dialogo de confirmação */

.text-muted-2 {
  color: #b5b5b5 !important;
}

.text-warning {
  color: #ffae00 !important;
}

input.hide-spin::-webkit-outer-spin-button,
input.hide-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number].hide-spin {
  -moz-appearance: textfield;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b5b5b5 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b5b5b5 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #b5b5b5 !important;
}
</style>
