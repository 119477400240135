function baseUrl() {
    let hostname = window.location.hostname;
  
    hostname = hostname.replace('.local.sgmcloud.com.br', '');
    hostname = hostname.replace('.dev.sgmcloud.com.br', '');
    hostname = hostname.replace('.sgmcloud.com.br', '');
    hostname = hostname.replace('.sgmcloud.local', '');
  
    return `${process.env.VUE_APP_MAIN_API_PREFIX}${hostname}.${process.env.VUE_APP_MAIN_API}`;
}

export default {
    createSessao: { method: 'post', url: `${baseUrl()}/login` },
    sessao: { method: 'get', url: `${baseUrl()}/sessao` },
    login: { method: 'get', url: `${baseUrl()}/empresa` },
    resetPassword: { method: 'post', url: baseUrl() +'/reset-password' }
}
