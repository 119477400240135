import store from '../store'

export default async (to, from, next) => {
    const companyData = JSON.parse(localStorage.getItem('companyData'));

    if (companyData && companyData.companyName) {
        document.title = `${to.name} - ${companyData.companyName} | SGM Cloud`
    } else {
        document.title = `${to.name} - SGM Cloud`
    }

    if (to.name !== 'Login' && !store.getters['auth/hasToken']) {
        try {
            await store.dispatch('auth/ActionCheckToken')
            //next({ name: to.name })
            next()
        } catch (error) {
            next({ name: 'Login' })
        }
    } else {
        if (to.name === 'Login' && store.getters['auth/hasToken']) {
            //desativado temporariamente devido a estar mandando URLs de volta pra home
            //next({ name: 'Home' })
        } else {
            next()
        }
    }
}